<script>
import Layout from "@/router/layouts/franchise";
import { api } from "@/state/services";
import { required } from "vuelidate/lib/validators";

export default {
  locales: {
    pt: {
      Inventory: "Estoque",
      Orders: "Pedidos",
      "Payment Method": "Forma de Pagamento",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Switch plans": "Trocar de Plano",
      "Current choice": "Escolha Atual",
      Choose: "Escolher",
      "Plan name": "Nome do Plano",
      Plans: "Planos",

      "Awaiting payment": "Aguardando pagamento",
      "Payment confirmed": "Pagamento confirmado",

      Address: "Endereço",
      "Send exactly value to make payment":
        "Envie exatamente o valor para efetuar o pagamento",

      "The order was not found.": "O pedido não foi encontrado.",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Pedimos desculpas, não podemos recuperar informações no momento. Por favor, tente novamente mais tarde.",
      "No records found.": "Nenhum registro encontrado.",

      "Have a voucher?": "Possui um voucher?",
      Validate: "Validar",
      "Voucher is required.": "O voucher é obrigatório",
      "The voucher is invalid.": "O voucher é inválido.",
      "This voucher has already been used.": "Este voucher já foi utilizado.",

      "To validate your transaction, paste the transaction hash here":
        "Para validar sua transação, cole aqui a hash da transação",
      Send: "Enviar",

      "This order is not yours.": "Este pedido não é seu.",
      "XFB payment is not available for this order.":
        "O pagamento com XFB não está disponível para este pedido.",
      "The informed transaction hash has already been used.":
        "A hash de transação informada já foi utilizada.",
      "Transaction hash could not be registered, try again.":
        "Não foi possível registrar a hahs da transação, tente novamente.",

      "Click here to pay": "Clique aqui para pagar",
    },
    es: {
      Inventory: "Estoque",
      Orders: "Pedidos",
      "Payment Method": "Forma de Pag",
      "Order Date": "Data do Pedido",

      Items: "Itens",
      Item: "Item",
      Price: "Preço",
      Qty: "Qtd",
      Value: "Valor",
      Status: "Status",
      Date: "Data",

      "Switch plans": "Trocar de Plano",
      "Current choice": "Escolha Atual",
      Choose: "Escolher",
      "Plan name": "Nome do Plano",
      Plans: "Planos",

      "Awaiting payment": "En espera de pago",
      "Payment confirmed": "Pago confirmado",

      Address: "Dirección",
      "Send exactly value to make payment":
        "Enviar exactamente el valor de hacer el pago",

      "The order was not found.": "O pedido não foi encontrado.",

      "We apologize, we are unable to retrieve information at this time. Please try again later.":
        "Nos disculpamos, no podemos recuperar información en este momento. Por favor, inténtelo de nuevo más tarde.",
      "No records found.": "No se encontraron registros.",

      "Have a voucher?": "¿Tienes un cupón?",
      Validate: "Validar",
      "Voucher is required.": "Se requiere el cupón",
      "The voucher is invalid.": "El cupón no es válido.",
      "This voucher has already been used.": "Este cupón ya ha sido utilizado.",

      "To validate your transaction, paste the transaction hash here":
        "Para validar la transacción, pegar el hash transacción aquí",
      Send: "Enviar",

      "This order is not yours.": "Este pedido no es tuyo.",
      "XFB payment is not available for this order.":
        "XFB pago no está disponible para este fin.",
      "The informed transaction hash has already been used.":
        "El hash transacción informada ya se ha utilizado.",
      "Transaction hash could not be registered, try again.":
        "El hash de la transacción no se pudo registrar, intente nuevamente.",

      "Click here to pay": "Haga clic aquí para pagar",
    },
  },
  components: {
    Layout,
  },
  data() {
    return {
      loading: true,
      errored: false,
      empty: false,
      plans: null,

      order: {
        id: this.$route.params.id,
        voucher: false,
        date: null,

        subtotal: "0.00",
        discount: "0.00",
        shipping: {
          method: "",
          value: "0.00",
          address: {
            zipcode: "",
            address: "",
            number: "",
            complement: "",
            district: "",
            city: "",
            state: "",
          },
        },
        payment: {
          methods: [],
          list: [],
        },

        total: "0.00",
        topay: "0.00",

        items: null,
        status: null,
      },

      voucher: {
        loading: false,
        alert: {
          type: "",
          message: "",
        },
        code: "",
      },
    };
  },
  validations: {
    voucher: {
      code: { required },
    },
  },
  methods: {
    getOrder() {
      if (this.order.id) {
        api
          .get("franchise/inventory/orders/" + this.order.id)
          .then((response) => {
            if (response.data.status == "success") {
              this.order = response.data.order;
              this.loading = false;
            } else {
              this.empty = true;
              this.message = response.data.message;
            }
          })
          .catch((error) => {
            this.errored = error;
          });
      }
    },
  },
  mounted() {
    this.getOrder();
  },
};
</script>

<template>
  <Layout>
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex justify-content-between">
          <h4 class="mb-0 font-size-18">
            {{ t("Inventory") }} › {{ t("Orders") }}
          </h4>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-10 mx-auto">
        <div class="card">
          <div class="card-body p-4">
            <div v-if="errored">
              {{
                t(
                  "We apologize, we are unable to retrieve information at this time. Please try again later."
                )
              }}
            </div>
            <div v-else-if="empty">{{ t("No records found.") }}</div>
            <div v-else class="p-2">
              <div class="d-flex">
                <h4 class="mb-0 font-size-15 flex-fill">#{{ order.id }}</h4>
                <h4 class="mb-0 font-size-15 flex-fill text-right">
                  {{ order.date }}
                </h4>
              </div>
              <hr />
              <div class="table-responsive">
                <table class="table table-nowrap">
                  <thead>
                    <tr>
                      <th class="border-0"></th>
                      <th class="border-0 text-uppercase">{{ t("Item") }}</th>
                      <th class="border-0 text-uppercase">{{ t("Price") }}</th>
                      <th class="border-0 text-uppercase">{{ t("Qty") }}</th>
                      <th class="border-0 text-uppercase text-right">
                        {{ t("Total") }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(td, index) in order.items" :key="index">
                      <td class="p-0" style="width: 1%">
                        <img
                          v-if="td.image"
                          style="width: 50px; height: 50px; object-fit: cover"
                          class=""
                          :src="td.image"
                        />
                      </td>
                      <td class="align-middle">{{ td.name }}</td>
                      <td class="align-middle">{{ td.price | currency }}</td>
                      <td class="align-middle">{{ td.quantity }}</td>
                      <td class="align-middle text-right">
                        {{ td.subtotal | currency }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="row mt-5">
                <div class="col-lg-6 mb-4">
                  <div v-if="order.shipping.address.zipcode">
                    <h3 class="font-size-16 mb-3">
                      {{ t("Endereço de entrega") }}
                    </h3>
                    {{ order.shipping.address.address }},
                    {{ order.shipping.address.number }},
                    <span v-if="order.shipping.address.complement"
                      >{{ order.shipping.address.complement }},
                    </span>
                    {{ order.shipping.address.district }}<br />
                    {{ order.shipping.address.city }}/{{
                      order.shipping.address.state
                    }},
                    {{ order.shipping.address.zipcode }}
                  </div>
                  <a
                    v-if="
                      order.shipping.tracking && order.shipping.tracking.code
                    "
                    target="_blank"
                    :href="order.shipping.tracking.url"
                    class="btn btn-default btn-sm mt-1 p-2 align-middle"
                  >
                    <i
                      class="bx bxs-truck font-size-17 text-white mr-1 align-top"
                    ></i>
                    Rastrear pedido
                  </a>
                </div>
                <div class="col-lg-6 mb-4">
                  <h3 class="font-size-16 mb-3">
                    {{ t("Detalhes do pagamento") }}
                  </h3>
                  <div class="table-responsive">
                    <table class="table table-nowrap table-borderless">
                      <tbody>
                        <tr v-if="order.discount != '0.00'">
                          <td class="px-0 py-1">{{ t("VOUCHER") }}</td>
                          <td class="px-0 py-1 text-right">
                            - {{ order.discount | currency }}
                          </td>
                        </tr>
                        <tr v-if="order.subtotal != order.total">
                          <td class="px-0 py-1">{{ t("SUBTOTAL") }}</td>
                          <td class="px-0 py-1 text-right">
                            {{ order.subtotal | currency }}
                          </td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1">
                            {{ t("FRETE") }} — {{ order.shipping.method }}
                          </td>
                          <td class="px-0 py-1 text-right">
                            + {{ order.shipping.value | currency }}
                          </td>
                        </tr>
                        <tr>
                          <td class="px-0 py-1 font-weight-bold">
                            {{ t("TOTAL") }}
                          </td>
                          <td class="px-0 py-1 font-weight-bold text-right">
                            {{ order.total | currency }}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <h3 class="font-size-16 mb-2">{{ t("Status") }}</h3>
              <div v-for="(td, index) in order.status" :key="index">
                {{ td.date }} — {{ t(td.status) }}
              </div>
              <b-tabs
                v-if="order.payment.status === 'pending'"
                justified
                nav-class="nav-tabs-custom mt-5"
                content-class="p-3 text-muted"
              >
                <b-tab
                  v-for="(pay, method) in order.payment.methods"
                  :key="method"
                >
                  <template v-slot:title>{{ pay.name }}</template>
                  <div class="text-center">
                    <img
                      class="mt-1 mb-4"
                      height="50px"
                      src="@/assets/images/payments/logo-mercadopago.svg"
                    /><br />
                    <a
                      target="_blank"
                      class="btn btn-default btn-lg"
                      :href="pay.url"
                      >{{ t("Click here to pay") }}</a
                    >
                  </div>
                </b-tab>
              </b-tabs>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
